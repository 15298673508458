.App {
  min-height: 100vh;
}

.no-text-decor {
  text-decoration: none;
  color: inherit;
  cursor: pointer !important;
}

.no-text-decor:hover{
  text-decoration: none;
  color: inherit;
}

.img-object-fit-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img-object-fit-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.no-acc-shadow .accordion-button:focus {
  box-shadow: none;
}
